import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";

import { Location } from "@/types/location";

const useCurrentLocation = (): {
  location: Location;
  error: string;
} => {
  const { t } = useTranslation();
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      function (error) {
        if (error?.PERMISSION_DENIED)
          return setError(t("locationPermissionError"));
        if (error?.POSITION_UNAVAILABLE || error?.TIMEOUT)
          return setError(t("locationError"));
      }
    );
  }, [t]);

  return {
    location,
    error,
  };
};

export default useCurrentLocation;
