import React from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertProps,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

type Props = {
  message?: string;
};

const ErrorMessage = ({
  message,
  ...props
}: Props & AlertProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Alert status="error" p={3} my={5} {...props}>
      <AlertIcon />
      <AlertDescription>{message || t("fetchError")}</AlertDescription>
    </Alert>
  );
};

export default ErrorMessage;
