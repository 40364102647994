import axios from "axios";

export const onLikeOrDislikeProduct = async (
  productId: number,
  liked: boolean,
  refreshData?: () => void
) => {
  await axios.post(`/product/like/${productId}`, {
    liked,
  });
  if (refreshData) await refreshData();
};

export const FOOD_IMAGES = [
  {
    value: "food28",
    imageURL: "food28.png",
  },
  {
    value: "food29",
    imageURL: "food29.png",
  },
  {
    value: "food08",
    imageURL: "food08.png",
  },
  {
    value: "food22",
    imageURL: "food22.png",
  },
  {
    value: "food26",
    imageURL: "food26.png",
  },
  {
    value: "food10",
    imageURL: "food10.png",
  },
  {
    value: "food12",
    imageURL: "food12.png",
  },
  {
    value: "food01",
    imageURL: "food01.png",
  },
  {
    value: "food11",
    imageURL: "food11.png",
  },
  {
    value: "food02",
    imageURL: "food02.png",
  },
  {
    value: "food15",
    imageURL: "food15.png",
  },
  {
    value: "food16",
    imageURL: "food16.png",
  },
  {
    value: "food19",
    imageURL: "food19.png",
  },
  {
    value: "food17",
    imageURL: "food17.png",
  },
  {
    value: "food18",
    imageURL: "food18.png",
  },
  {
    value: "food20",
    imageURL: "food20.png",
  },
  {
    value: "food07",
    imageURL: "food07.png",
  },
  {
    value: "food03",
    imageURL: "food03.png",
  },
  {
    value: "food21",
    imageURL: "food21.png",
  },
  {
    value: "food23",
    imageURL: "food23.png",
  },
  {
    value: "food06",
    imageURL: "food06.png",
  },
  {
    value: "food30",
    imageURL: "food30.png",
  },
  {
    value: "food31",
    imageURL: "food31.png",
  },
  {
    value: "food05",
    imageURL: "food05.png",
  },
  {
    value: "food04",
    imageURL: "food04.png",
  },
  {
    value: "food14",
    imageURL: "food14.png",
  },
  {
    value: "food34",
    imageURL: "food34.png",
  },
  {
    value: "food33",
    imageURL: "food33.png",
  },
  {
    value: "food25",
    imageURL: "food25.png",
  },
  {
    value: "food32",
    imageURL: "food32.png",
  },
  {
    value: "food13",
    imageURL: "food13.png",
  },
  {
    value: "food27",
    imageURL: "food27.png",
  },
];
