import { TFunction } from "next-i18next";

export const getDistanceLabel = (
  t: TFunction,
  distance: string,
  longLabel?: boolean,
  fromGivenLocation?: boolean
): string =>
  parseFloat(distance) > 1
    ? t(`${longLabel && !fromGivenLocation ? "kilometersFromYou" : "kilometers"}`, {
        distance: parseFloat(distance).toFixed(),
      })
    : t(`${longLabel && !fromGivenLocation ? "lessThanKilometer" : "kilometer"}`);
