import React from "react";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { Clock } from "react-feather";
import { useTranslation } from "next-i18next";
import { differenceInDays } from "date-fns";

const AvailabilityBadge = ({ availabilityDate, ...rest }) => {
  const { t } = useTranslation();

  if (new Date(availabilityDate).getTime() <= new Date().getTime()) return null;

  return(
    <Flex
      alignItems="center"
      bg="green.500"
      borderRadius="5px"
      p="5px 7.5px"
      lineHeight={1}
      position="absolute"
      zIndex={9}
      top="30px"
      left="5px"
      {...rest}
    >
      <Icon as={Clock} w="10px" h="10px" color="white" />
      <Text
        ml="5px"
        fontSize="11px"
        color="white"
      >
        {t("products.availability", {
          days: differenceInDays(new Date(availabilityDate), new Date()),
        })}
      </Text>
    </Flex>
  )
};

export default AvailabilityBadge;
