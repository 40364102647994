import React from "react";
import {
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Icon,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { EmailIcon, FacebookIcon, WhatsappIcon } from "react-share";
import { Copy } from "react-feather";

import { useToastPromise } from "@/hooks/useToast";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  url: string;
};

const ShareModal = ({ isOpen, onClose, url }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const origin = typeof window !== "undefined" ? window.location.origin : "";
  const fullUrl = `${origin}${origin?.includes("uniqsoft") ? "/gs" : ""}${url}`;
  const toastPromise = useToastPromise();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"xl"}
      preserveScrollBarGap
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("share")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack spacing={5} align="center" justifyContent={"center"}>
            <Flex
              _hover={{
                opacity: 0.7,
              }}
              transition="transform 0.3s, opacity 0.3s"
            >
              <FacebookShareButton url={fullUrl}>
                <FacebookIcon size={50} round={true} />
              </FacebookShareButton>
            </Flex>
            <Flex
              _hover={{
                opacity: 0.7,
              }}
              transition="transform 0.3s, opacity 0.3s"
            >
              <WhatsappShareButton url={fullUrl}>
                <WhatsappIcon size={50} round={true} />
              </WhatsappShareButton>
            </Flex>{" "}
            <Flex
              _hover={{
                opacity: 0.7,
              }}
              transition="transform 0.3s, opacity 0.3s"
            >
              <EmailShareButton url={fullUrl}>
                <EmailIcon size={50} round={true} />
              </EmailShareButton>
            </Flex>
            <Tooltip
              hasArrow
              placement="top"
              label={t("copyPageAddressToClipboard")}
              bg={"red.500"}
            >
              <Flex
                rounded={"full"}
                bgColor="red.500"
                p={"11px"}
                align="center"
                justifyContent={"center"}
                cursor="pointer"
                onClick={() =>
                  toastPromise.promise(navigator.clipboard.writeText(fullUrl), {
                    success: t("copyToClipboardSuccess"),
                    error: t("copyToClipboardError"),
                  })
                }
                _hover={{
                  opacity: 0.7,
                }}
                transition="transform 0.3s, opacity 0.3s"
              >
                <Icon as={Copy} color="white" w={"25px"} h={"25px"} />
              </Flex>
            </Tooltip>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
