import React, { useState } from "react";
import { Box, Img, BoxProps, Flex } from "@chakra-ui/react";
import getConfig from "next/config";
import { ImageOff } from "lucide-react";

import Loader from "@/components/common/Loader";

export enum ImageState {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}

const { publicRuntimeConfig } = getConfig();

type Props = {
  fileName: string;
  alt?: string;
  defaultPhoto?: boolean;
};

const Image = ({
  fileName,
  alt = "",
  defaultPhoto = false,
  ...props
}: Props & BoxProps): React.ReactElement => {
  const [imageState, setImageState] = useState(ImageState.LOADING);

  return (
    <Box {...props}>
      {imageState === ImageState.LOADING && (
        <Loader position="absolute" left={0} right={0} top={0} bottom={0} />
      )}
      {imageState === ImageState.ERROR && (
        <Flex
          position="absolute"
          left={0}
          right={0}
          top={0}
          bottom={0}
          align="center"
          justifyContent={"center"}
          bg="#dedddd"
        >
          <ImageOff size={48} />
        </Flex>
      )}
      <Img
        src={
          defaultPhoto
            ? `${publicRuntimeConfig.basePath}/${fileName}.png`
            : `${publicRuntimeConfig.apiUrl}/uploads/${fileName}`
        }
        alt={alt}
        visibility={
          imageState === ImageState.LOADING || imageState === ImageState.ERROR
            ? "hidden"
            : "visible"
        }
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        objectFit="cover"
        w="100%"
        h="100%"
        onLoad={() => setImageState(ImageState.LOADED)}
        onError={() => setImageState(ImageState.ERROR)}
      />
    </Box>
  );
};

export default Image;
