import { StarIcon } from "@chakra-ui/icons";
import { Box, Icon, Tooltip } from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import React from "react";
import { Heart, Share2, Star } from "react-feather";

import { onLikeOrDislikeGarage } from "@/utils/garage";
import { onLikeOrDislikeProduct } from "@/utils/product";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type LikeAndShareIconsProps = {
  isUserGarage: boolean;
  isProduct?: boolean;
  favourite: boolean;
  garageId: number;
  productId?: number;
  garageRefresher?: () => void;
  w: number | string;
  h: number | string;
  onShare: () => void;
};

const LikeAndShare = ({
  isUserGarage,
  isProduct = false,
  garageId,
  productId,
  favourite,
  garageRefresher,
  w,
  h,
  onShare,
}: LikeAndShareIconsProps): React.ReactElement => {
  const { t } = useTranslation();
  const { status } = useSession();

  return (
    <>
      {!isUserGarage && !isProduct && status === "authenticated" && (
        <Tooltip
          hasArrow
          placement="top"
          bg={"yellow.500"}
          label={favourite ? t("removeFromFavourites") : t("addToFavourites")}
        >
          <Icon
            cursor="pointer"
            as={favourite ? StarIcon : Star}
            w={w}
            h={h}
            color="yellow.500"
            onClick={() =>
              onLikeOrDislikeGarage(garageId, !favourite, garageRefresher)
            }
            ml={5}
          />
        </Tooltip>
      )}
      {isProduct && status === "authenticated" && (
        <Tooltip
          hasArrow
          placement="top"
          bg={"red.500"}
          label={favourite ? t("removeFromFavourites") : t("addToFavourites")}
        >
          <Box zIndex={10} color="red.500" cursor={"pointer"}>
            {favourite ? (
              <FontAwesomeIcon
                icon={faHeart}
                width={w}
                height={h}
                color="inherit"
                onClick={() =>
                  onLikeOrDislikeProduct(productId, !favourite, garageRefresher)
                }
              />
            ) : (
              <Icon
                zIndex={99}
                as={Heart}
                w={w}
                h={h}
                color="inherit"
                onClick={() => {
                  onLikeOrDislikeProduct(
                    productId,
                    !favourite,
                    garageRefresher
                  );
                }}
              />
            )}
          </Box>
        </Tooltip>
      )}
      <Tooltip hasArrow placement="top" bg={"blue.500"} label={t("share")}>
        <Icon
          cursor="pointer"
          as={Share2}
          w={w}
          h={h}
          color="blue.500"
          ml={2}
          onClick={() => onShare()}
        />
      </Tooltip>
    </>
  );
};

export default LikeAndShare;
